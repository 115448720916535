import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_URL} from "@monorepo/ui/core";
import {makeUrl} from "@monorepo/ui/helpers";
import {DICTIONARIES_ORDERS_API} from "@monorepo/dictionaries/shared";
import {Observable} from "rxjs";
import {OrderTypeDto} from "@monorepo/dictionaries/dto";
const { CONTROLLER, ORDER_INTEGRATION_TYPES } = DICTIONARIES_ORDERS_API;

@Injectable()
export class OrdersIntegrationTypesService {
  private http = inject(HttpClient);
  private apiUrl = inject(API_URL);
  private BASE_URL = makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  public load(): Observable<OrderTypeDto[]> {
    const url = makeUrl(this.BASE_URL, ORDER_INTEGRATION_TYPES().url);
    return this.http.get<OrderTypeDto[]>(url);
  }
}
