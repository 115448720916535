import {NgModule} from "@angular/core";
import {ZonesForStorageService} from "./zones-for-storage.service";
import {ZonesForStorageDictionaryState} from "./state/zones-for-storage.state";
import {provideStates} from "@ngxs/store";
import {ZonesForStorageFacade} from "./state/zones-for-storage.facade";

@NgModule({
  providers: [
    ZonesForStorageService,
    provideStates([ZonesForStorageDictionaryState]),
    ZonesForStorageFacade,
  ]
})
export class ZonesForStorageDictionaryModule {}
