import {inject, Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {ZonesForStorageDictionaryState} from "./zones-for-storage.state";
import {map} from "rxjs/operators";

@Injectable()
export class ZonesForStorageFacade {
  private store = inject(Store);
  public items$ = this.store.select(ZonesForStorageDictionaryState.getState).pipe(
    map((state) => state.items)
  )
}
