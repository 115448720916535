import {NgModule} from "@angular/core";
import {OrdersIntegrationTypesService} from "./orders-integration-types.service";
import {OrdersIntegrationTypeDictionaryState} from "./state/orders-integration-types.state";
import {provideStates} from "@ngxs/store";
import {OrdersIntegrationTypesFacade} from "./state/orders-integration-types.facade";

@NgModule({
  providers: [
    OrdersIntegrationTypesService,
    provideStates([OrdersIntegrationTypeDictionaryState]),
    OrdersIntegrationTypesFacade,
  ]
})
export class OrdersIntegrationTypesDictionaryModule {}
