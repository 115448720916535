import {IntegrationListGroupDto} from "@monorepo/dictionaries/dto";
import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {SnackbarService} from "@monorepo/ui/services/snackbar";
import {ListGroupsForIntegrationService} from "../list-groups-for-integration.service";
import * as Actions from './list-groups-for-integration.actions';
import {catchError, Observable} from "rxjs";
import {tap} from "rxjs/operators";

export interface ListGroupsForIntegrationStateModel {
  items: IntegrationListGroupDto[];
  loading: boolean;
}

const defaults: ListGroupsForIntegrationStateModel = {
  items: [],
  loading: false,
}

type Model = ListGroupsForIntegrationStateModel;
type Context = StateContext<Model>;

@State<Model>({
  name: 'listGroupsForIntegrationDictionary',
  defaults,
})
@Injectable()
export class ListGroupsForIntegrationsDictionaryState implements NgxsOnInit {
  private service = inject(ListGroupsForIntegrationService)
  private snack = inject(SnackbarService);

  @Selector()
  public static getState(state: Model): Model {
    return state;
  }

  public ngxsOnInit({dispatch}: Context) {
    dispatch(new Actions.Load())
  }

  @Action(Actions.Load)
  public load({patchState, dispatch}: Context): Observable<void | IntegrationListGroupDto[]> {
    patchState({loading: true});
    return this.service.load().pipe(
      tap((data) => {
        patchState({
          loading: false,
          items: data,
        })
      }),
      catchError((err) =>
        dispatch(new Actions.ApiError(err))
      )
    )
  }

  @Action(Actions.ApiError)
  public apiError({patchState}: Context, { error }: Actions.ApiError) {
    patchState( { loading: false });
    this.snack.catchApiError({error});
  }
}
