import {NgModule} from "@angular/core";
import {BusinessPartnersForIntegrationService} from "./business-partners-for-integration.service";
import {BusinessPartnersForIntegrationDictionaryState} from "./state/business-partners-for-integration.state";
import {provideStates} from "@ngxs/store";
import {BusinessPartnersForIntegrationFacade} from "./state/business-partners-for-integration.facade";

@NgModule({
  providers: [
    BusinessPartnersForIntegrationService,
    provideStates([BusinessPartnersForIntegrationDictionaryState]),
    BusinessPartnersForIntegrationFacade,
  ]
})
export class BusinessPartnersForIntegrationDictionaryModule {}
