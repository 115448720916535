import {NgModule} from "@angular/core";
import {ConditionsForIntegrationService} from "./conditions-for-integration.service";
import {ConditionsForIntegrationDictionaryState} from "./state/conditions-for-integration.state";
import {provideStates} from "@ngxs/store";
import {ConditionsForIntegrationFacade} from "./state/conditions-for-integration.facade";

@NgModule({
  providers: [
    ConditionsForIntegrationService,
    provideStates([ConditionsForIntegrationDictionaryState]),
    ConditionsForIntegrationFacade,
  ]
})
export class ConditionsForIntegrationDictionaryModule {}
