import {NgModule} from "@angular/core";
import {ListGroupsForIntegrationService} from "./list-groups-for-integration.service";
import {ListGroupsForIntegrationsDictionaryState} from "./state/list-groups-for-integration.state";
import {provideStates} from "@ngxs/store";
import {ListGroupsForIntegrationFacade} from "./state/list-groups-for-integration.facade";

@NgModule({
  providers: [
    ListGroupsForIntegrationService,
    provideStates([ListGroupsForIntegrationsDictionaryState]),
    ListGroupsForIntegrationFacade,
  ]
})
export class ListGroupsForIntegrationDictionaryModule {}
